import {
    AnalyticsManager,
    createPluginGA,
    createPluginYM,
} from '@s7/analytics';

import { GA_ID, YA_ID } from '../config';
import { eventNames } from 'process';

const createAppUserIdContext = () => {
    let profileId: string;

    function set(id: string) {
        profileId = id;
    }

    function get() {
        return profileId;
    }

    return {
        get,
        set,
    };
};

export const userId = createAppUserIdContext();

export const analytics = AnalyticsManager({
    transform: (eventName, payload) => ({
        application: 'myprofile',
        event_name: eventName,
        flow: 'profile',
        ...payload,
    }),
    plugins: [
        createPluginGA({
            initialize: { containerId: GA_ID },
        }),
        createPluginYM({
            initialize: () => ({
                counterId: YA_ID,
                instanceKey: 'ym',
                status: () => true,
            }),
            transform: (eventName, payload) => ({
                user_id: userId.get(),
                ...payload,
            }),
        }),
    ],
});

export const profileInfoModule = analytics.createTopic(
    (eventName, payload) => ({
        ...payload,
        flow: 'profile_info',
    }),
);

export const milesModule = analytics.createTopic((eventName, payload) => ({
    ...payload,
    flow: 'miles',
}));

export const profileModule = analytics.createTopic();
