import { COOKIES } from 'consts';
import { cookieService } from 'services';

/**
 * Определяет посещал ли пользователь новый портал, основываясь на куке portal.
 *
 * @returns {boolean}
 */
export const visitedPortal = (): boolean =>
    Boolean(Number(cookieService.get(COOKIES.PORTAL)));
