import { PORTAL_URL } from 'src/shared/config';

import {
    NewSlimCompassLinear,
    NewSlimHomeLinear,
    NewSlimInfoLinear,
    NewSlimProfileFilled,
    NewSlimSearchTicketLinear,
} from '@s7/icons';

import { NavigationStep, TabNavigationItem } from './types';

export const items: TabNavigationItem[] = [
    {
        value: NavigationStep.MAIN,
        href: PORTAL_URL,
        icon: NewSlimHomeLinear,
    },
    {
        value: NavigationStep.TRIPS,
        href: `${PORTAL_URL}/?tab=${NavigationStep.TRIPS}`,
        icon: NewSlimCompassLinear,
    },
    {
        value: NavigationStep.SEARCH,
        href: `${PORTAL_URL}/?tab=${NavigationStep.SEARCH}`,
        icon: NewSlimSearchTicketLinear,
    },
    {
        value: NavigationStep.INFO,
        href: `${PORTAL_URL}/?tab=${NavigationStep.INFO}`,
        icon: NewSlimInfoLinear,
    },
    {
        value: NavigationStep.PROFILE,
        href: '/',
        icon: NewSlimProfileFilled,
    },
];

export const LANGUAGES_NAMESPACE = ['global'];
