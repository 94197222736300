import { createStyles } from '@s7/styles';

export const useBadgeStyles = createStyles({
    badge: {
        alignItems: 'center',
        borderRadius: 12,
        display: 'flex',
        justifyContent: 'center',
        padding: '0 12px',
    },
});
