import OldHeader from 'containers/Header';
import { useStyleVariantContext } from 'contexts';
import { useBreakpoint } from 'hooks';
import { SessionService } from 'services';
import { visitedPortal } from 'src/shared/lib/visited-portal';
import { Header } from 'src/widgets/header';
import { MediaHeader } from 'src/widgets/media-header';

import { Space } from '@s7/layout';

import cx from './MainLayout.sss';

export const LayoutHeader = () => {
    const isAuth = SessionService.isAuth();
    const isMobile = useBreakpoint().only('xs');
    const { variant } = useStyleVariantContext();

    return visitedPortal() ? (
        <>
            <Header />
            <Space
                className={cx('header_space', {
                    header_space_media: variant === 'media',
                    header_space_login: !isAuth,
                })}
                height={68}
            />
        </>
    ) : (
        <>
            <div className={cx('header')}>
                {variant === 'media' ? <MediaHeader /> : <OldHeader />}
            </div>
            {variant !== 'media' && <Space height={isMobile ? 52 : 80} />}
        </>
    );
};
