import { FlagsEN, FlagsRU } from '@s7/icons';

export const languages = [
    {
        code: 'en',
        label: 'English',
        icon: <FlagsEN />,
    },
    {
        code: 'ru',
        label: 'Русский',
        icon: <FlagsRU />,
    },
];

export const LANGUAGES_NAMESPACE = ['global'];
