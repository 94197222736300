import { createStyles } from '@s7/styles';

export const useLabelStyles = createStyles({
    disabled: {
        opacity: '0.6',
    },
    label: {
        alignItems: 'center',
        borderRadius: '50%',
        display: 'flex',
        height: 36,
        justifyContent: 'center',
        minWidth: 36,
        width: 36,
    },
});
