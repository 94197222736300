import React from 'react';
import Dictionary from 'shared/Dictionary';

import { Box } from '@s7/base';
import { PolymorphicColor } from '@s7/styles';
import { Typography } from '@s7/ui';

import { useBadgeStyles } from './styles';

type BadgeProps = {
    textColor: PolymorphicColor;
    textSize: string;
    text: string;
    className?: string;
};

export const Badge = ({
    textSize,
    textColor,
    text,
    className,
}: BadgeProps): JSX.Element => {
    const [classes, cx] = useBadgeStyles();

    return (
        <Box className={cx(classes.badge, className)}>
            <Typography color={textColor} size={textSize} variant={'text'}>
                <Dictionary text={text} />
            </Typography>
        </Box>
    );
};
