import { createStyles } from '@s7/styles';

export const useMediaHeaderStyles = createStyles(({ invariants, fn }) => ({
    top: {
        'div &': {
            backgroundColor: '#A1BFA5',
            borderBottomColor: fn.rgba(invariants.white[100], 0.15),
            [fn.down('md1')]: {
                borderBottom: 'none',
            },
        },
    },
}));
