import { createStyles } from '@s7/styles';

export const useAvatarIconStyles = createStyles(({ fn: { down } }) => ({
    icon: {
        backgroundSize: 'cover',
        borderRadius: '50%',
        cursor: 'pointer',
        height: '28px',
        width: '28px',
    },
    root: {
        'div &': {
            height: '32px',
            marginRight: '12px',
            [down('sm1')]: {
                display: 'none',
            },
        },
    },
}));
