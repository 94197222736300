export type HeaderProfile = {
    firstName?: string;
    lastName?: string;
    avatarUrl?: string;
    miles?: number;
};

export enum ProfileType {
    FULL = 'FULL',
    SHORT = 'SHORT',
    TEMPORARY = 'TEMPORARY',
}
