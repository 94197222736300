import { useDispatch, useSelector } from 'react-redux';
import NextLink from 'components/Link';
import { useLanguages } from 'hooks';
import { SessionService } from 'services';
import { useChangeLanguage } from 'src/features/language';
import { PORTAL_URL } from 'src/shared/config';
import { analytics } from 'src/shared/lib/analitics-manager';
import { selectLocale } from 'store/modules/languages';
import { getMiles } from 'store/modules/loyalty';
import {
    getAvatarPreviewLink,
    getProfileData,
    getProfileNames,
    isLoading,
} from 'store/modules/profile';
import { setFooterChatWidgetOpen } from 'store/modules/shared';

import {
    Header as S7Header,
    HeaderChat,
    HeaderLanguage,
    HeaderLogo,
    HeaderProfile,
} from '@s7/header';

import { languages, LANGUAGES_NAMESPACE } from '../constants';
import { ProfileType } from '../types';

export const Header = () => {
    const dictionary = useLanguages(LANGUAGES_NAMESPACE);
    const dispatch = useDispatch();
    const isAuth = SessionService.isAuth();
    const loading = useSelector(isLoading);
    const avatarUrl = useSelector(getAvatarPreviewLink);
    const { name, surname } = useSelector(getProfileNames);
    const profile = useSelector(getProfileData);
    const miles = useSelector(getMiles);
    const language = useSelector(selectLocale);
    const onChangeLanguage = useChangeLanguage();

    const onClickChat = () => {
        dispatch(
            (setFooterChatWidgetOpen as (value: boolean) => unknown)(true),
        );
    };

    return (
        <S7Header
            rightSlot={
                <>
                    {isAuth && (
                        <HeaderProfile
                            profile={{
                                firstName: name ?? '',
                                lastName: surname ?? '',
                                avatarUrl,
                                ...(profile.type === ProfileType.FULL && {
                                    miles: miles ?? 0,
                                }),
                            }}
                            as={NextLink}
                            href="/"
                            loading={loading}
                            loginLabel=""
                        />
                    )}

                    <HeaderLanguage
                        data={languages}
                        title={dictionary.t('com.label.language.select')}
                        value={language}
                        onChange={onChangeLanguage}
                    />
                    <HeaderChat onClick={onClickChat} />
                </>
            }
            analytics={analytics}
            leftSlot={<HeaderLogo as="a" href={PORTAL_URL} />}
        />
    );
};
