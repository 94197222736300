import { createStyles, xref } from '@s7/styles';

export const useUserMediaInfoStyles = createStyles(theme => ({
    avatar: {
        marginRight: '62px',
        ref: xref('avatar'),

        [theme.fn.down('sm1')]: {
            marginRight: '16px',
        },
    },

    buttonClass: {
        '&:active': {
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
        },

        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
        },

        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        height: '40px',
        padding: '10px 16px',
        width: 'fit-content',
    },

    buttonsContainers: {
        display: 'grid',
        gap: '8px',
        gridAutoFlow: 'column',

        [theme.fn.down('sm1')]: {
            gridRowStart: 1,
            justifyContent: 'flex-start',
        },
    },

    col: {
        '&:not(:first-of-type)': {
            marginLeft: '23px',
        },

        '&:not(:last-child)': {
            borderRight: `1px solid ${theme.invariants.white[100]}`,
            paddingRight: '23px',
        },

        [theme.fn.down('sm1')]: {
            alignItems: 'flex-end',
            display: 'flex',
        },
    },

    count: {
        [theme.fn.down('sm1')]: {
            marginRight: '4px',
        },
    },

    iconButton: {
        padding: '13px',
    },

    iconButtonRightSide: {
        margin: 0,
    },

    info: {
        overflow: 'hidden',
        ref: xref('info'),
        width: '100%',
    },

    infoLoader: {
        [theme.fn.down('sm1')]: {
            width: '335px',
        },

        '@media (max-width: 599px)': {
            width: '160px',
        },
    },

    infoRoot: {
        display: 'flex',
    },

    isPublic: {
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',

        [`& .${xref('avatar')}`]: {
            margin: 0,
        },

        [`& .${xref('info')}`]: {
            marginTop: '20px',
            padding: 0,
            width: 'auto',
        },

        [`& .${xref('name')}`]: {
            margin: 0,
        },
    },

    name: {
        display: 'flex',
        marginBottom: '8px',
        ref: xref('name'),

        [theme.fn.down('sm1')]: {
            marginBottom: '2px',
        },
    },

    root: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        paddingTop: '54px',

        [theme.fn.down('sm1')]: {
            gap: '16px',
            gridTemplateColumns: 'initial',
            gridTemplateRows: 'auto auto',
            justifyContent: 'flex-start',
            paddingTop: '0',
        },
    },

    row: {
        display: 'flex',
        marginTop: '48px',

        [theme.fn.down('sm1')]: {
            marginTop: '12px',
        },
    },
}));
