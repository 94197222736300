import React from 'react';
import NextLink from 'components/Link';
import { useLanguages } from 'hooks';
import { AnalyticEvents } from 'src/shared/constants/analytics';
import { profileModule } from 'src/shared/lib/analitics-manager';

import { CLASSNAME_FULL_WIDTH } from '@s7/base';
import { Affix } from '@s7/layout';
import { TabNavigation as TabNavigationUI } from '@s7/ui';

import { items, LANGUAGES_NAMESPACE } from '../constants';
import { NavigationStep } from '../types';

export const TabNavigation = () => {
    const dictionary = useLanguages(LANGUAGES_NAMESPACE);

    const createSendClickTabNavigationItem = (toTab: NavigationStep) => () => {
        profileModule.sendEvent(AnalyticEvents.click, {
            result: 'open_tab',
            step: toTab,
        });
    };

    return (
        <Affix
            sx={{
                width: '100%',
            }}
            position={{ bottom: 0, left: 0 }}
        >
            <TabNavigationUI
                activeTab={'profile'}
                className={CLASSNAME_FULL_WIDTH}
            >
                {items.map(item => (
                    <TabNavigationUI.Item
                        key={item.value}
                        title={dictionary.t(
                            `com.s7.tab.navigation.${item.value}.page`,
                        )}
                        as={NextLink}
                        href={item.href as string}
                        icon={item.icon}
                        value={item.value}
                        onClick={createSendClickTabNavigationItem(item.value)}
                    />
                ))}
            </TabNavigationUI>
        </Affix>
    );
};
