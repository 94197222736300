import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PageContainer from 'components/PageContainer';
import Breadcrumbs from 'containers/Breadcrumbs';
import ProfileHeader from 'containers/ProfileHeader';
import { useAppContext, useGoogleAnalyticContext } from 'contexts';
import { useApi, useLanguages } from 'hooks';
import { arrayOf, bool, node, oneOf, shape, string } from 'prop-types';
import { AnalyticEvents } from 'src/shared/constants/analytics';
import { profileModule } from 'src/shared/lib/analitics-manager';
import { visitedPortal } from 'src/shared/lib/visited-portal';
import { Footer } from 'src/widgets/footer';
import { TabNavigation } from 'src/widgets/tab-navigation';

import { CLASSNAME_FULL_WIDTH, CLASSNAME_ZERO_RIGHT } from '@s7/base';

import { LayoutHeader } from './LayoutHeader';
import cx from './MainLayout.sss';
import priorityPic from './priority-pic.png';

const priorityPicStyle = {
    backgroundImage: `url(${priorityPic})`,
};

const sendInfoEvent = sendUserInfoEvent => {
    if (window) {
        setTimeout(() => sendUserInfoEvent(), 500);
    }
};

const priorityImage = ({ withBigBreakpoint }) => (
    <div
        className={cx('priority-image', CLASSNAME_ZERO_RIGHT, {
            'priority-image-big-breakpoint': withBigBreakpoint,
        })}
        style={priorityPicStyle}
    />
);

const MainLayout = ({
    background,
    breadcrumbs,
    children,
    languages,
    modules,
    profileHeader,
    title,
    withBigBreakpoint,
    withPriorityPicture = true,
}) => {
    useApi(modules);
    const dictionary = useLanguages(languages);
    const { sendUserInfoEvent } = useGoogleAnalyticContext();
    const { inApp } = useAppContext();

    useEffect(() => {
        sendInfoEvent(sendUserInfoEvent);
    }, [sendUserInfoEvent]);

    useEffect(() => {
        if (visitedPortal()) {
            profileModule.sendEvent(AnalyticEvents.viewScreen, {
                result: 'show_tab',
            });
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>S7 Airlines | {dictionary.t(title)}</title>
            </Helmet>
            <div className={cx('root')}>
                {!inApp && <LayoutHeader />}
                <PageContainer
                    className={cx('container', CLASSNAME_FULL_WIDTH)}
                >
                    <div
                        className={cx('background', {
                            [`background_${background}`]: background,
                        })}
                    >
                        {withPriorityPicture &&
                            priorityImage({ withBigBreakpoint })}
                        {breadcrumbs && (
                            <div
                                className={cx('breadcrumbs', {
                                    breadcrumbs_white: profileHeader,
                                })}
                            >
                                <Breadcrumbs
                                    background={
                                        !profileHeader ? background : undefined
                                    }
                                    items={breadcrumbs}
                                />
                            </div>
                        )}
                        <div className={cx('content')}>
                            {profileHeader && <ProfileHeader />}
                            {children}
                        </div>
                    </div>
                </PageContainer>
                {!inApp && <Footer />}
                {!inApp && visitedPortal() && <TabNavigation />}
            </div>
        </>
    );
};

MainLayout.propTypes = {
    background: oneOf(['green', 'G50', 'N30']),
    breadcrumbs: arrayOf(
        shape({
            href: string,
            title: node,
        }),
    ),
    children: node,
    languages: arrayOf(string),
    modules: arrayOf(string),
    profileHeader: bool,
    title: string,
    withBigBreakpoint: bool,
    withPriorityPicture: bool,
};

export default MainLayout;
